<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">套餐列表</span>
      <div class="a-flex-rcc">
        <el-button
          icon="el-icon-plus"
          type="primary"
          class="s-btn-add a-ml-24"
          @click="handlerAddCoupon"
        >
          套餐
        </el-button>
      </div>
    </div>

    <!-- 搜索 -->
    <el-card class="el-main">
      <le-search-form :can-pack-up="false" @reset="handlerRest" @search="handlerSearch">
        <le-select-local-search
          v-model="pageParams.params.companyId"
          label="所属商户"
          :options="agentList"
        />
        <le-input-icon-search v-model="pageParams.params.name" label="套餐名称" clearable />
        <le-select-local-search
          v-model="pageParams.params.type"
          label="套餐类型"
          :options="couponTypeList"
        />
        <le-date-range
          label="更新日期"
          :min-date.sync="pageParams.params.updateTimeBegin"
          :max-date.sync="pageParams.params.updateTimeEnd"
        />
      </le-search-form>

      <!-- 表格 -->
      <le-pagview :page-param="pageParams" :total.sync="pageParams.total" @setData="setTableData">
        <el-table
          v-sticky="{ parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
        >
          <el-table-column label="序号" type="index" />
          <el-table-column label="所属商户(简称)" prop="companyName" min-width="160" />
          <el-table-column label="套餐名称" prop="name" min-width="120" />
          <el-table-column label="套餐金额（元）" prop="price" min-width="120" />
          <el-table-column label="套餐有效期（天）" prop="expireDay" min-width="140" />
          <el-table-column label="套餐类型" prop="typeText" />
          <el-table-column label="套餐充电次数" prop="chargeNumText" min-width="120" />
          <el-table-column label="每日充电次数（次）" prop="oneDayChargeNum" min-width="180" />
          <el-table-column
            label="最大时长（分钟）"
            prop="oneTimeChargeDurationNum"
            min-width="180"
          />
          <el-table-column label="生效中数量" prop="effectuateNum" min-width="100" />
          <el-table-column label="更新时间" prop="updateTime" min-width="170" />
          <el-table-column label="操作" width="120">
            <template slot-scope="{ row }">
              <el-button type="text" @click="onSales(row)">售卖</el-button>
              <el-button type="text" @click="onViewDetail(row)">详情</el-button>
              <el-popconfirm
                title="删除后不可恢复，请确认是否删除该套餐"
                @confirm="onDeleteCoupon(row)"
              >
                <el-button v-if="row.effectuateNum === 0" slot="reference" type="text">
                  <span class="_delete">删除</span>
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </el-card>

    <!-- 新增售卖 -->
    <sales-dialog
      v-model="addSales.toggle"
      :data="addSales.data"
      @clear="addSales.data = {}"
      @success="handlerSearch"
    />
  </div>
</template>

<script>
import axios from "axios";

import SalesDialog from "./components/SalesDialog";

export default {
  name: "CouponList",
  components: {
    SalesDialog,
  },
  data() {
    return {
      pageParams: {
        url: this.$Config.apiUrl.getCouponList,
        method: "post",
        params: {
          companyId: "",
          name: "",
          type: "",
          updateTimeBegin: "",
          updateTimeEnd: "",
        },
        total: 0,
        freshCtrl: 1,
      },
      // 商户列表
      agentList: [],
      // 套餐卡类型
      couponTypeList: [],
      // 表格数据
      tableData: [],
      // 新增售卖
      addSales: {
        toggle: false,
        data: {},
      },
    };
  },
  computed: {
    couponTypeListMap() {
      return this.couponTypeList.reduce((map, item) => {
        map[item.id] = item.name;
        return map;
      }, {});
    },
  },
  created() {
    axios.all([this.getAgentList(), this.getCouponTypeList()]);
  },
  methods: {
    handlerAddCoupon() {
      this.$router.push("/coupon/add-coupon");
    },
    handlerRest() {
      this.pageParams.params = {
        companyId: "",
        name: "",
        type: "",
        updateTimeBegin: "",
        updateTimeEnd: "",
      };
    },
    handlerSearch() {
      this.pageParams.freshCtrl++;
    },
    setTableData(data) {
      this.tableData = (data || []).map((item) => ({
        ...item,
        price: (item.price || 0) / 100,
        typeText: this.couponTypeListMap[item.type],
        chargeNumText: item.chargeNumType === 1 ? "不限" : item.chargeNum || "--",
      }));
    },
    // 获取商户列表
    getAgentList() {
      return new Promise((resolve) => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.getAgentList,
          })
          .then((res) => {
            this.agentList = (res.data || []).map((item) => ({
              ...item,
              label: item.name,
              value: item.id,
            }));
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    // 获取套餐卡类型
    getCouponTypeList() {
      return new Promise((resolve) => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.getCouponTypeList,
          })
          .then((res) => {
            this.couponTypeList = (res.data || []).map((item) => ({
              ...item,
              label: item.name,
              value: item.id,
            }));
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    // 删除套餐卡
    onDeleteCoupon(row) {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.deleteCoupon,
          params: {
            cardId: row.id,
          },
        })
        .then(() => {
          this.$message.success("删除套餐成功");
          this.handlerSearch();
        });
    },
    // 新增售卖
    onSales(row) {
      this.addSales = {
        toggle: true,
        data: row || {},
      };
    },
    // 套餐详情
    onViewDetail(row) {
      this.$router.push(`/coupon/coupon-detail/${row.id}`);
    },
  },
};
</script>

<style scoped>
._delete {
  margin-left: 10px;
  color: red;
}
</style>
